<template>
  <div class="container-fluid">
    <div class="col-lg-12 mt-5">
      <Wizard/>
    </div>
  </div>
   
  </template>
  
  <script>
  import Wizard from 'src/pages/Forms/Wizard.vue';
  
  export default {
    name: 'Update',
    components: {
      Wizard,
    },
    data() {
      return {
       
      };
    },
    methods: {
     
    },
  };
  </script>
  
  <style scoped>
  </style>
  