export const getOptions = (
  showLegend,
  seriesLabel,
  seriesUnit,
  seriesSelected,
  x_axis_type,
  x_axis_label
) => {
  return {


    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: 'nearest',
      intersect: 0,
      position: 'nearest'
    },
    interaction: {
      intersect: true,
    },
    //radius: radius,

    responsive: true,
    maintainAspectRatio: false,

    legend: {
      display: showLegend,
      position: "bottom",
      align: "center",

      fullSize: true,
      labels: {
        usePointStyle: true,
        boxWidth: 4,
        padding: 2,
        fontStyle: "bold",
      },
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontStyle: "bold",
            minor: {},
            major: {},
            padding: 0,
            autoSkip: true,
            labelOffset: 0
          },
          scaleLabel: {
            fontStyle: "bold",
            display: true,
            labelString:
              seriesLabel.filter((s) => s.value === seriesSelected)[0].text +
              seriesUnit.filter((s) => s.value === seriesSelected)[0].text,
          },
        },
      ],
      xAxes: [
        {
          type: x_axis_type,
          stacked: true,
          ...(x_axis_type === 'time' ? {
            type: "time",
            time: {
              unit: "hour",
              tooltipFormat: "HH:mm",
              minUnit: "minute",
              stepSize: 1,
              displayFormats: {
                hour: "HH"
              }
            }
          } : {}),
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(225,78,202,0.1)',
            zeroLineColor: 'transparent'
          },


          ticks: {
            beginAtZero: false,
            fontSize: 12,
            fontStyle: "bold",
            lineHeight: 0.5,
            minor: {},
            major: {},
            padding: 0,
            autoSkip: true,
            minRotation: 0,
            maxRotation: 180,
            labelOffset: 0,
            scaleLabel: {
              fontStyle: "bold",
              labelString: x_axis_label,
              display: true,
              labelString: "Hours"

            },
            legend: {
              display: false
            }
          }

        },
      ],
    },
    plugins: {

    },
  };
};
export const getBarOptions = (
  showLegend,
  seriesLabel,
  seriesUnit,
  seriesSelected,
  x_axis_type,
  x_axis_label
) => {
  return {
    fill: true,
    interaction: {
      intersect: false,
    },
    //radius: radius,
    aspectRatio: 1.3,
    responsive: true,
    maintainAspectRatio: false,

    legend: {
      display: showLegend,
      position: "bottom",
      align: "center",

      fullSize: true,
      labels: {
        usePointStyle: true,
        boxWidth: 4,
        padding: 2,
        fontStyle: "bold",
      },
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            // min:"03",
            // max:"23",
            // stepSize:2,
            fontSize: 12,
            //maxTicksLimit: 10,
            fontStyle: "bold",
            // lineHeight: 0.5,
            minor: {},
            major: {},
            padding: 0,
            autoSkip: true,
            // minRotation: 0,
            // maxRotation: 180,
            labelOffset: 0,
          },
          scaleLabel: {
            fontStyle: "bold",
            display: true,
            labelString:
              seriesLabel.filter((s) => s.value === seriesSelected)[0].text +
              seriesUnit.filter((s) => s.value === seriesSelected)[0].text,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          type: x_axis_type,
          time: {
            unit: "hour",
            tooltipFormat: "HH:mm",
            minUnit: "minute",
            stepSize: 1,
            displayFormats: {
              hour: "HH",
            },
          },
          //position:'left',
          //offset:true,
          gridLines: {
            // circular:false,
            // color:'rgba(3, 1, 0, 55)'
          },
          ticks: {
            beginAtZero: false,
            // min:"03",
            // max:"23",
            // stepSize:2,
            fontSize: 12,
            //maxTicksLimit: 10,
            fontStyle: "bold",
            lineHeight: 0.5,
            minor: {},
            major: {},
            padding: 0,
            autoSkip: true,
            minRotation: 0,
            maxRotation: 180,
            labelOffset: 0,
            scaleLabel: {
              display: true,
            },
          },
          scaleLabel: {
            fontStyle: "bold",
            display: true,
            labelString: x_axis_label,
          },
          legend: {
            display: false,
          },
          // type: 'time',
          // bounds: 'labels',
          // time: {
          //   displayFormats: {
          //     quarter: 'MMM YYYY'
          //   }
          // }
        },
      ],
    },
    plugins: {
      zoom: {
        // Container for pan options
        pan: {
          // Boolean to enable panning
          enabled: true,

          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          // A function that is called as the user is panning and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: "xy",

          rangeMin: {
            // Format of min pan range depends on scale type
            x: null,
            y: null,
          },
          rangeMax: {
            // Format of max pan range depends on scale type
            x: null,
            y: null,
          },

          // On category scale, factor of pan velocity
          speed: 20,

          // Minimal pan distance required before actually applying pan
          threshold: 10,

          // Function called while the user is panning
          onPan: function ({ chart }) {
            // console.log(`I'm panning!!!`);
          },
          // Function called once panning is completed
          onPanComplete: function ({ chart }) {
            // console.log(`I was panned!!!`);
          },
        },

        // Container for zoom options
        zoom: {

          // wheel: {
          //   enabled: true,
          // },
          // pinch: {
          //   enabled: true
          // },
          // Boolean to enable zooming
          enabled: true,

          // Enable drag-to-zoom behavior
          drag: false,

          // Drag-to-zoom effect can be customized
          // drag: {
          //      borderColor: 'rgba(225,225,225,0.3)'
          //      borderWidth: 5,
          //      backgroundColor: 'rgb(225,225,225)',
          //      animationDuration: 0
          // },

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          // A function that is called as the user is zooming and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: "xy",

          rangeMin: {
            // Format of min zoom range depends on scale type
            x: null,
            y: null,
          },
          rangeMax: {
            // Format of max zoom range depends on scale type
            x: null,
            y: null,
          },

          // Speed of zoom via mouse wheel
          // (percentage of zoom on a wheel event)
          speed: 0.1,

          // Minimal zoom distance required before actually applying zoom
          threshold: 2,

          // On category scale, minimal zoom level before actually applying zoom
          sensitivity: 3,

          // Function called while the user is zooming
          onZoom: function ({ chart }) {
            // console.log(`I'm zooming!!!`);
          },
          // Function called once zooming is completed
          onZoomComplete: function ({ chart }) {
            // console.log(`I was zoomed!!!`);
          },
        },
      },
    },
  };
};


export const getConfig = (chartType, height, data, options) => {
  return {
    type: chartType,
    plugins: [
      {
        beforeInit: function (chart, options) {
          chart.legend.afterFit = function () {
            height = height - 5;
            //height = width= +35;
            chart.legend.options.labels.padding = 5;
          };
        },

        beforeDraw: (chart) => {
          if (chartType != "bar") {
            var ctx = chart.chart.ctx;
            ctx.save();
            let xAxis = chart.scales["x-axis-0"];

            let yAxis = chart.scales["y-axis-0"];
            let datasets = chart.data.datasets;

            var valueFrom = null;
            var valueFromIndex = 0;
            var xFrom = null;
            let yFrom = null;
            datasets.forEach((dataset) => {
              if (dataset._meta[Object.keys(dataset._meta)[0]].hidden != true) {
                ctx.strokeStyle = dataset.borderColor;

                dataset.data.forEach((value, index) => {
                  if (value != null) {
                    // var x = xAxis.getPixelForTick(index);
                    var x =
                      dataset._meta[Object.keys(dataset._meta)[0]].data[index]
                        ._model.x;

                    var y = yAxis.getPixelForValue(value);

                    if (xFrom != null && x != null) {
                      ctx.lineWidth = dataset.borderWidth;
                      if (index - valueFromIndex > 1) {
                        ctx.setLineDash([5, 5]);
                        ctx.beginPath();
                        ctx.moveTo(xFrom, yFrom);
                        ctx.lineTo(x, y);
                        ctx.stroke();
                      }
                    }
                    valueFrom = value;
                    valueFromIndex = index;
                    xFrom = x;
                    yFrom = y;
                  }
                });
                ctx.restore();
              }
            });
          }
        },
      },
    ],
    data,
    options,
  };
};
