<template>
    <div>

        <div class=" container-fluid ">
            <div class="row mt-5">
                <div class="col col-sm-12 d-flex justify-content-center ">

                    <div class="card-style card">
                        <h1 class="text-center">{{ $t('settings.header') }}</h1>
                        <div class="card-container">
                            <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }" type="text"
                                :placeholder="$t('settings.serialnumber1')" v-model="values.serialNumber1">

                            <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }" type="text"
                                :placeholder="$t('settings.serialnumber2')" v-model="values.serialNumber2">
                                <div v-if="count >= 1">
                                    <div v-for="key in count" :key="key">
                                        <input 
                                            :class="{ 'text-white': !darkMode, 'text-dark': darkMode }"
                                            type="text" 
                                            :placeholder="$t('settings.another')" 
                                            v-model="values['dynamic-field-' + key]" 
                                            :id="key"
                                        >
                                    </div>
                                </div>

                        </div>
                        <div :class="{ 'controler': true, 'text-white': !darkMode, 'text-dark': darkMode }" style="cursor: pointer;">
                            <a id="add_more_fields" @click="add"><i class="fa fa-plus"></i>{{$t('settings.addmore')}}</a>
                            <a id="remove_fields" @click="remove"><i class="fa fa-minus"></i>{{$t('settings.removefields')}}</a>

                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <button class="btnn btn-info" @click="submit">{{$t('settings.save')}}</button>
                        </div>
                    </div>



                </div>

            </div>

        </div>



    </div>
</template>
  
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useApi } from "@/modules/api";
import axios from 'axios';
import { mapState } from 'vuex';





export default {
    name: 'Settings',
    data() {
        return {
            count: 1,
            values: {
                serialNumber1: '',
                serialNumber2: '',
            },
        };
    },
    methods: {
        add() {
            this.count++;
        },
        remove() {
            if (this.count >= 1) {
                this.count--;
            }
            else {
                this.count = 0;
            }
        },
        async submit() {
            const numericArray = [];
            for (const key of Object.keys(this.values)) {
                const value = this.values[key];
                // console.log('Value:', value);
                if ((value)) {
                    if (value != 0) {
                        numericArray.push(value);
                    }
                }
            }
            // const allUpperCaseLetters = numericArray.every(item => /^[A-Z]+$/.test(item));
            // if (!allUpperCaseLetters){
            //     Swal.fire({
            //         title: ' ',
            //         text: this.$t('settings.uppercase'),
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: "#285891",
            //     });
            //     return;
            // }
            // console.log('Numeric array:', numericArray);
            this.resetInputs();

            if (numericArray.length > 0) {
                try {



                    const token = localStorage.getItem("access_token");
                    const requestBody = {
                        sn: numericArray // Convert input values to numbers
                    };
                    const endpoint = '/api/auth/add_sn/';

                    const url = process.env.VUE_APP_API_ENDPOINT + endpoint;
                    const headers = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json' 
                    };

                    const response = await axios.post(url, requestBody, { headers });
                  //  const message =JSON.stringify(response.data);
                    // console.log('Data:', response.data);
                    const failed = this.$t('settings.failedby');
                    const success = this.$t('settings.successby');
                    const message = `${success} ${response.data["success by"].length}: [${response.data["success by"]}]<br>${failed} ${response.data["failed by"].length}: [${response.data["failed by"]}]<br> ${this.$t('settings.relog')}`;
                    Swal.fire({
                        title: '',
                        html : message, 
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#285891",
                    });

                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                     

                        Swal.fire({
                            title: ' ',
                            text: this.$t('settings.errorMessage'),
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#285891",
                        });
                    } else {
                        console.log('Unknown error occurred:', error);
                    }
                }
            }
            else {
                Swal.fire({
                    title: ' ',
                    text: this.$t('settings.message'),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#285891",
                });
            }

        },
        resetInputs() {
            for (const key of Object.keys(this.values)) {
                this.values[key] = '';
            }
        },
    },
    computed: {
        ...mapState(['darkMode']),
        darkMode() {
            return this.$store.getters.getDarkMode;
        },
    },

};
</script>
  
<style scoped>
/* For iPad (Tablet) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .card-style {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 20px;
    }
}

/* For Normal Phones */
@media screen and (max-width: 767px) {
    .controler {
        width: 100% !important;
        margin: 15px auto;
    }

    .btnn {
        width: 50% !important;
    }

    h1 {
        text-align: center;
        font-size: 1.2em !important;


    }

    .card-style {

        width: 100% !important;
        height: 100%;
        border-radius: 5px;
        padding: 20px;
    }
}

.card-container {
    width: 100%;
}

.card-style {
    width: 50%;
    height: 100%;
    border-radius: 5px;
    padding: 20px;
}

#add_more_fields i {
    padding-right: 10px;
    /* Adjust the padding as needed */
}

#remove_fields i {
    padding-right: 10px;
    /* Adjust the padding as needed */
}

.btnn {
    width: 20%;

    color: white;
    border: 0;
    padding: 7px;
    border-radius: 5px;


}

.wrapper {
    width: 50%;
    height: 50%;
    margin: 40px auto;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 10px 40px 0px rgba(47, 47, 47, 0.1);
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px auto;
    display: block;
    border-radius: 5px;
    border: 1px solid #555;
    width: 100%;
    background: none;

}

input[type="text"]:focus {
    outline: none;
}

.controler {
    width: 60%;
    margin: 15px auto;
}

#remove_fields {
    float: right;

}

.controle a i.fa-minus {
    margin-right: 10px;
}

h1 {
    text-align: center;
    font-size: 2em;


}

.text-white {
    color: #000 !important;
}

.text-dark {
    color: #fff !important;
}
</style>
  