<template>
    <div>
        <canvas ref="myChartCanvas" height="200"></canvas>
    </div>
</template>
  
<script>
import Chart from 'chart.js';


export default {
    name: 'MyChart',
    props: {
        lineData: {
            type: Array,
            required: true
        },
        labelData: {
            type: Array,
            required: true
        }
        , areaColor: {
            type: Array,
            required: true
        }
        , LineBorderColor: {
            type: String,
            required: true
        }, toolTipeLabel: {
            type: String,
            required: true
        }
        , scaleLabelName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            myChart: null,

            chartData: {

                labels: this.labelData,
                datasets: [{
                    borderColor: this.LineBorderColor,
                    pointBackgroundColor: this.LineBorderColor,
                    pointBorderColor: "rgba(255,255,255,0)",
                    label: this.toolTipeLabel,
                    data: this.lineData,
                    backgroundColor: this.areaColor,

                    showLine: true,
                    pointBorderWidth: 10,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 1,
                    borderWidth: 2,
                    borderDash: [],


                }]
            },
            chartOptions: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                responsive: true,
                tension: 0.4,
                tooltips: {
                    backgroundColor: '#f5f5f5',
                    titleFontColor: '#333',
                    bodyFontColor: '#666',
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: 'nearest',
                    intersect: 0,
                    position: 'nearest'
                },

                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 12,
                                fontStyle: "oblique",
                                minor: {},
                                major: {},
                                padding: 0,
                                autoSkip: true,
                                labelOffset: 0
                            },
                            scaleLabel: {
                                fontStyle: "bold",
                                display: true,
                                labelString: this.scaleLabelName
                            }
                        }
                    ],

                    xAxes: [
                        {
                            stacked: true,
                            type: "time",
                            time: {
                                unit: "day",
                                tooltipFormat: "DD",
                                minUnit: "day",
                                stepSize: 1,
                                displayFormats: {
                                    day: "DD"
                                }
                            },
                            barPercentage: 1.6,
                            gridLines: {
                                drawBorder: false,
                                color: 'rgba(225,78,202,0.1)',
                                zeroLineColor: 'transparent'
                            },
                            ticks: {
                                beginAtZero: false,
                                fontSize: 12,
                                fontStyle: "oblique",
                                lineHeight: 0.5,
                                minor: {},
                                major: {},
                                padding: 0,
                                autoSkip: true,
                                minRotation: 0,
                                maxRotation: 180,
                                labelOffset: 0,
                                scaleLabel: {
                                    fontStyle: "bold",

                                    display: true,
                                    labelString: "Hours"

                                },
                                legend: {
                                    display: false
                                }
                            }
                        }
                    ]
                }



            }
        };
    },
    mounted() {
        this.createChart();
    },
    watch: {
        lineData: {
            handler(newData) {
                // Update the chart with new line data
                this.updateChart(newData, this.labelData);
            },
            deep: true // Watches changes deeply in the array
        },
        labelData: {
            handler(newLabels) {
                // Update the chart with new label data
                this.updateChart(this.lineData, newLabels);
            },
            deep: true // Watches changes deeply in the array
        }
    },
    methods: {
        updateChart(newLineData, newLabelData) {
            if (this.myChart) {
                // Update chart labels and data
                this.myChart.data.labels = newLabelData;
                this.myChart.data.datasets[0].data = newLineData;

                // Update or reconfigure any other chart properties here if needed

                // Update the chart
                this.myChart.update();
            }
        }
        ,
        createChart() {
            const ctx = this.$refs.myChartCanvas.getContext('2d');
            this.myChart = new Chart(ctx, {
                type: 'line',
                plugins: [{

                    beforeDraw: (chart) => {

                        var ctx = chart.chart.ctx;
                        ctx.save();
                        let xAxis = chart.scales["x-axis-0"];

                        let yAxis = chart.scales["y-axis-0"];
                        let datasets = chart.data.datasets;

                        var valueFrom = null;
                        var valueFromIndex = 0;
                        var xFrom = null;
                        let yFrom = null;
                        datasets.forEach((dataset) => {
                            if (dataset._meta[Object.keys(dataset._meta)[0]].hidden != true) {
                                ctx.strokeStyle = dataset.borderColor;

                                dataset.data.forEach((value, index) => {
                                    if (value != null) {
                                        // var x = xAxis.getPixelForTick(index);
                                        var x =
                                            dataset._meta[Object.keys(dataset._meta)[0]].data[index]
                                                ._model.x;

                                        var y = yAxis.getPixelForValue(value);

                                        if (xFrom != null && x != null) {
                                            ctx.lineWidth = dataset.borderWidth;
                                            if (index - valueFromIndex > 1) {
                                                ctx.setLineDash([5, 5]);
                                                ctx.beginPath();
                                                ctx.moveTo(xFrom, yFrom);
                                                ctx.lineTo(x, y);
                                                ctx.stroke();
                                            }
                                        }
                                        valueFrom = value;
                                        valueFromIndex = index;
                                        xFrom = x;
                                        yFrom = y;
                                    }
                                });
                                ctx.restore();
                            }
                        });

                    },
                }],
                data: this.chartData,
                options: this.chartOptions
            });
        }
    },
    beforeDestroy() {
        if (this.myChart) {
            this.myChart.destroy();
        }
    }
};
</script>
  
<style>
/* Add your component-specific styles here if needed */
</style>
  