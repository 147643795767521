export const saxMaps = {
	getMeldung: (id) => {
	
		
		switch (id) {
	
			case 1:
				return 'U >>'
			case 2:
				return 'U>'
			case 3:
				return 'U <<'
			case 4:
				return 'U <'
			case 5:
				return 'f >'
			case 6:
				return 'f <'
			case 7:
				return 'Insel'
			case 8:
				return 'I >>'
			case 9:
				return 'I >'
			case 10:
				return 'Relais'
			case 11:
				return 'U!'
			case 12:
				return 'SM!'
			case 13:
				return 'M!'
			default:
				return id
		}
	},
	getOnOff: (id) => {
		/*
		switch (id) {
			case 1:
				return false
			case 2:
				return true
			case 3: // ???
				return true
			case 4:
				return false
		}
		*/
		switch (id) {
			case 0:
				return false
			case 1:
				return true
			case 2: // ???
				return true
			case 4:
				return false
		}
	},
	getLang: (id) => {
		switch (id) {
			case 0:
				return 'en'
			case 1:
				return 'de'
		}
	}
}
