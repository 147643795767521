<template>
  <div id="dashChart">
    <div class="row">
      <div class="col pr-1 col-xl-5">
        <b-form-select :class="{
          'rounded': true,
          'm-0': true,
          'p-0': true,
          'pl-1': true,
          'text-black': !darkMode,
          'text-white': darkMode
        }" v-model="seriesSelected" :options="seriesLabel" @change="drawChart" />
      </div>
      <div class="col col-lg-2 pl-1 pr-lg-1">

        <b-form-select :class="{
          'rounded': true,
          'm-0': true,
          'p-0': true,
          'pl-1': true,
          'text-black': !darkMode,
          'text-white': darkMode
        }" v-model="timeSelected" :options="timeSeries" @change="drawChart" />


      </div>
      <div class="col-12 col-lg-5 mt-2 mt-lg-0 pl-lg-1">

        <div :class="{ 'border': true, 'rounded': true, 'text-black': !darkMode, 'text-white': darkMode }">

          <VueDatePicker style="padding-bottom: 4px;" v-model="date" color="#285891" fullscreen-mobile
            @onChange="drawChart" :allow-overflow="false" :locale="{ lang: lang }" :type="timeSelected == 'day' 
          ? 'date'
          : timeSelected == 'quarter'
            ? 'date'
            : timeSelected
          " :max-date="today" />
        </div>
      </div>
    </div>
    <div class="mt-3 position-relative">
      <div class="chart-wrapper" style="height:29cqmax">
        <canvas id="chart" ref="chartWrapper" />
      </div>
    </div>
    <div v-if="seriesSelected != 'm0'" style="position: absolute; right: .5vw; bottom: 2.5vh;">
      <button class="btn btn-primary" style="padding: 1px;" :title="$t('bigchart.Export CSV')"
        @click="exportToCSV(seriesSelected)">
        <i class="mdi mdi-download-outline"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as Chart from "chart.js";
import { getConfig, getOptions, getBarOptions } from "./chart/configs";
import i18n from "@/i18n";
import { EventBus } from 'src/EventBus.js';
import { createDataSet, getProcessedChartData, createbarDataSet } from "./chart/utils";
import {
  getSeriesUnit,
  getSeriesLabel,
  getSeries,
  getTimeSeries,
} from "./chart/constants";
export default {
  name: "Chart",
  components: {},
  data() {
    return {
      chartData: null,
      isLoading: false,
      chart: null,
      data: null,
      curentChartData: null,
      canvasContext: null,
      allChartData: null,
      today: new Date(),
      hasM1: true,
      timeSelected: this.$store.getters.chartSelector.timeSelected,
      seriesSelected: this.$store.getters.chartSelector.seriesSelected,
      date:
        this.$store.getters.chartSelector.date == ""
          ? this.$dayjs().format("ddd MMM DD YYYY 00:mm:ss")
          : this.$store.getters.chartSelector.date,
      showLegend: false,
      x_axis_label: this.$i18n.t('bigchart.day'),
      x_axis_type: "category",
      intervalId: null,
    };
  },

  created() {

  },
  watch: {
    curentSn(newVal, oldVal) {
      if (newVal != oldVal) this.drawChart();
    },
    seriesSelected: function (newSeriesSelected, oldSeriesSelected) {
      this.$store.commit("setUpdateChart", true);
      // console.log("oldSeriesSelected: ", oldSeriesSelected, "newSeriesSelected: ", newSeriesSelected, "timeSelected: ", this.timeSelected);
      if (newSeriesSelected == "m3" || newSeriesSelected == "m7") {
        if (this.timeSelected == "quarter") {
          this.timeSelected = "day";
        }
        this.hasM1 = false;

      } else if (oldSeriesSelected == "m3" || oldSeriesSelected == "m7") {
        if (this.timeSelected == "day") {
          this.timeSelected = "quarter";
        }
        this.hasM1 = true;
      }
    },
  },
  methods: {
    exportToCSV(seriesSelected) {
      const seriesMapping = {
        m2: i18n.t("bigchart.Gird Power") + " in [W]",
        m4: i18n.t("bigchart.PV_power") + " in [W]",
        m5: i18n.t("bigchart.Battery power") + " in [W]",
        m6: i18n.t("bigchart.SOC") + " in [%]",
        m7: `${i18n.t("bigchart.Energy") + " in [Wh]"},${i18n.t("bigchart.Charging")}, ${i18n.t("bigchart.Discharging")}`,
      };
      const data_sel = seriesMapping[seriesSelected] || "";

      const formatDate = (label, timeSelected) => {
        const date = new Date(this.date);
        if (timeSelected === 'month') {
          date.setDate(label);
          return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + label).slice(-2)}`;
        } else if (timeSelected === 'year') {
          return `${date.getFullYear()} ${label}`;

        } else if (timeSelected === 'day') {
          // console.log("fulldasDate: ", label);

          return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${label}:${('0' + date.getMinutes()).slice(-2)}`;
        
        } else {
          const fullDate = new Date(label);
          return `${fullDate.getFullYear()}-${('0' + (fullDate.getMonth() + 1)).slice(-2)}-${('0' + fullDate.getDate()).slice(-2)} ${('0' + fullDate.getHours()).slice(-2)}:${('0' + fullDate.getMinutes()).slice(-2)}`;
        }
      };

      if (this.chartData && this.chartData.labels && this.chartData.datasets) {
        let csvContent = `data:text/csv;charset=utf-8,\nDateTime,${data_sel}\n`;
        // console.log("this.chartData: ", this.chartData);
        this.chartData.labels.forEach((label, index) => {
          const formattedDate = formatDate(label, this.timeSelected);
          const roundedValue = Math.round(this.chartData.datasets[0].data[index]);
          if (seriesSelected === 'm7') {
            const chargingValue = typeof (chargingValue) != undefined ? this.chartData.datasets[0].data[index] : "";
            const dischargingValue = typeof (dischargingValue) != undefined ? this.chartData.datasets[1].data[index] : "";
            csvContent += `${formattedDate},${chargingValue},${dischargingValue}\n`;
          } else
            csvContent += `${formattedDate},${roundedValue}\n`;
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI);
        link.setAttribute("download", "chart_data.csv");
        document.body.appendChild(link);
        link.click();
      } else {
        console.error('chartData is not properly defined.');
      }
    },
    alignDataSets(data) {

      let maxLength = 0;
      let referenceTimes = [];
      Object.keys(data).forEach(sn => {
        if (data[sn].length > maxLength) {
          maxLength = data[sn].length;
          referenceTimes = data[sn].map(entry => {

            const timestampString = entry.qm_time;
            const datePart = timestampString.substring(0, timestampString.lastIndexOf(":"));
            return `${datePart}`;
          });

        }
      });

      Object.keys(data).forEach(sn => {
        const snDataByTime = {};

        data[sn].forEach(entry => {
          // Create a trimmed version of qm_time for comparison
          const trimmedTime = entry.qm_time.substring(0, entry.qm_time.lastIndexOf(":"));
          snDataByTime[trimmedTime] = entry;
        });

        const alignedData = referenceTimes.map(time => {
          if (snDataByTime[time]) {
            return snDataByTime[time];

          } else {
            return {
              qm_time: `${time}:00.000000`,
              qm_m1: null,
              qm_m2: null,
              qm_m4: null,
              qm_m5: null,
              qm_m6: null

            };
          }
        });

        data[sn] = alignedData.sort((a, b) => new Date(a.qm_time) - new Date(b.qm_time));
      });

      return data;
    },

    allChartDataSummery(arr) {
      let arrNew = [];
      let sns = Object.keys(arr);

      for (let index = 0; index < arr[sns[0]].data.length; index++) {
        let gg = Object.keys({ ...arr[sns[0]].data[0] }).reduce(
          (accumulator, currentValue) => {
            if (
              currentValue == "time" ||
              currentValue == "sn_id" ||
              currentValue == "id"
            )
              accumulator[currentValue] = arr[sns[0]].data[index][currentValue];
            else {
              try {
                var temp = 0;
                var value = 0;
                var dividedNumber =
                  currentValue == "m3" || currentValue == "m7" ? 1 : 0;
                for (let i = 0; i < sns.length; i++) {
                  temp =
                    typeof arr[sns[i]].data[index][currentValue] ===
                      "undefined" ||
                      isNaN(parseInt(arr[sns[i]].data[index][currentValue]))
                      ? null
                      : parseInt(arr[sns[i]].data[index][currentValue]);
                  value += temp;
                  // console.log("sns1111: ", sns[i],value);
                  if (
                    temp != null &&
                    value != 0 &&
                    temp != 0 &&
                    currentValue != "m3" &&
                    currentValue != "m7"
                  )
                    dividedNumber += 1;
                  // console.log("sns1111: ", sns[i],currentValue,"value::::",value ,"temp::::",temp);
                }
                if (value != 0) {

                  accumulator[currentValue] = (value / dividedNumber).toFixed(0);
                }
                // console.log( "value::::",value)
                if (
                  temp != null && currentValue != "m6" &&
                  currentValue != "m3" &&
                  currentValue != "m2" &&
                  currentValue != "m7") {
                  accumulator[currentValue] = value;
                }
                else if (temp != null && value == 0 && (currentValue == "m6" || currentValue == "m2")) {

                  accumulator[currentValue] = value;
                }

                else if (value == 0 && temp != 0 &&
                  (currentValue == "m0" ||
                    currentValue == "m2" ||
                    currentValue == "m5")) {

                  accumulator[currentValue] = null
                }


              } catch (error) {
                console.log("error: ", error);
              }
            }
            return accumulator;
          },
          {}
        );
        arrNew.push(gg);
      }

      return { data: arrNew, x_axis_type: arr[sns[0]].x_axis_type };
    },
    dateChanged() {
      if (this.timeSelected === "month") {
        this.date = this.$dayjs(this.date).format("ddd MMM DD YYYY 00:mm:ss");
      } else {
        this.date = this.$dayjs(this.date).format("ddd MMM DD YYYY hh:mm:ss");
      }
      //console.log("dataLabel: ", this.seriesSelected);
      //this.getData();
    },
    createChartData(selectedSeries, radius = 1) {
      if (this.seriesSelected == "m0") {
        this.showLegend = true;
        let data2 = this.data.map((d) => d["m2"]);
        let data5 = this.data.map((d) => d["m5"]);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [
            createDataSet(i18n.t("bigchart.Gird Power") + " (" + i18n.t("bigchart.Input_output") + ")", data2, '#42b883'),
            createDataSet(i18n.t("bigchart.Battery power") + " (" + i18n.t("bigchart.Charge_Discharge") + ")", data5, '#285891'),

          ],
        };
      }
      if (this.seriesSelected == "m2") {
        this.showLegend = true;
        let data = this.data.map((d) => d["m2"]);
        return {
          labels: this.data.map((x) => x.time),

          datasets: [
            createDataSet(
              this.$i18n.t("bigchart.Input_output"),
              data, '#42b883'
            ),
          ],
        };
      }
      if (this.seriesSelected == "m4") {
        this.showLegend = true;
        let data = this.data.map((d) => d["m4"]);
        return {
          labels: this.data.map((x) => x.time),

          datasets: [
            createDataSet(
              this.$i18n.t("bigchart.PV_power"),
              data, '#dac555'
            ),
          ],
        };
      }
      if (this.seriesSelected == "m5") {
        this.showLegend = true;
        let data = this.data.map((d) => d["m5"]);
        //console.log("data_out", data_out);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [
            createDataSet(

              this.$i18n.t("bigchart.Charge_Discharge"),

              data, '#42b883'
            ),
          ],
        };
      }

      if (this.seriesSelected == "m7") {
        this.showLegend = true;
        let data2 = this.data.map((d) => d["m7"]);
        let data5 = this.data.map((d) => d["m3"]);
        console.log("data_out_m3", data5);
        console.log("data_out_m7", data2);

        return {
          labels: this.data.map((x) => x.time),

          datasets: [
            createbarDataSet(i18n.t("bigchart.Energy") + " (" + i18n.t("bigchart.Charging") + ")", "#D5366D", "#D5366D", data2),
            createbarDataSet(i18n.t("bigchart.Energy") + " (" + i18n.t("bigchart.Discharging") + ")", "#45D17E", "#45D17E", data5),
          ],
        };
      }
      if (
        this.seriesSelected == "energy_forecast" ||
        this.seriesSelected == "load_forecast"
      ) {
        this.showLegend = false;
        let data = this.data.map((x) => x.data);
        //console.log("data_out", data_out);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [createDataSet("", data, '#42b883')],
        };
      }
      let data = this.data.map((d) => d[selectedSeries]);
      this.showLegend = false;
      return {
        labels: this.data.map((x) => x.time),
        datasets: [createDataSet("", data, '#285891')],
      };
    },
    getChartType() {
      if (this.seriesSelected == "m3" || this.seriesSelected == "m7") {
        return "bar";
      }
      return "line";
    },
    checkTimeAndDrawChart() {
      const date = new Date();
      const minutes = date.getMinutes();
      if ((minutes - 1) % 5 === 0) {
        this.drawChart();
      }
    },
    drawChart() {
      this.isLoading = true;
      this.dateChanged();
      this.getXLabel();
      this.isLoading = true;
      this.getData()
        .then(() => {
          if (this.chart) this.chart.destroy();
          // console.log("thsi.x_axis_type", this.x_axis_type)
          let data = this.createChartData(this.seriesSelected);
          this.chartData = data;

          if (this.getChartType() == "bar") {
            let options = getBarOptions(
              this.showLegend,
              this.seriesLabel,
              this.seriesUnit,
              this.seriesSelected,
              this.x_axis_type,
              this.x_axis_label
            );
            let config = getConfig(
              this.getChartType(),
              this.height,
              data,
              options
            );

            this.chart = new Chart(this.canvasContext, config);


          }
          else {
            let options = getOptions(
              this.showLegend,
              this.seriesLabel,
              this.seriesUnit,
              this.seriesSelected,
              this.x_axis_type,
              this.x_axis_label
            );



            let config = getConfig(
              this.getChartType(),
              this.height,
              data,
              options
            );

            this.chart = new Chart(this.canvasContext, config);
          }

        })
        .catch(() => {
          this.isLoading = false;
          //console.log("err");
        });
      this.isLoading = false;
      // to show the day label in chart selector
      if (
        this.$store.getters.sn == -1 &&
        this.timeSelected == "day" &&
        this.seriesSelected != "m3" &&
        this.seriesSelected != "m7"
      )
        this.timeSelected = "quarter";
    },
    getXLabel() {
      switch (this.timeSelected) {
        case "day":
          this.x_axis_label = this.$i18n.t("bigchart.Hours");
          break;
        case "month":
          this.x_axis_label = this.$i18n.t("bigchart.Days");
          break;
        case "year":
          this.x_axis_label = this.$i18n.t("bigchart.Monthes");
          break;
        default:
          this.x_axis_label = this.$i18n.t("bigchart.Hours");
          break;
      }
    },
    get_selected_methode() {
      if (
        this.seriesSelected == "energy_forecast" ||
        this.seriesSelected == "load_forecast"
      )
        return this.seriesSelected;
      else {
        return this.getChartType() == "bar" ? "sum" : "mean";
      }
    },
    getData() {
      // This function is reponsilbe for apply the current selected settings in chart to the server and return in turn the data that belogs to
      // Test if the chart selected series is not allowed to be selected in overview model
      let not_allowed = ["m1", "m2", "m4"];
      if (
        this.$store.getters.sn == -1 &&
        not_allowed.includes(this.seriesSelected)
      ) {
        this.seriesSelected = "m0";
      }
      if (this.$store.getters.sn == -1 && this.timeSelected == "Day")
        this.timeSelected = "day";
      this.$store.commit("setChartSelector", {
        seriesSelected: this.seriesSelected,
        timeSelected: this.timeSelected,
        method: this.get_selected_methode(),
        date: this.date,
      });
      return this.$store
        .dispatch("get_selected_data", this.$store.getters.chartSelector)
        .then(() => {
          try {

            //Processing the data returns from the server
            this.allChartData = getProcessedChartData(
              this.$store.getters.allChartData,
              this.x_axis_type,
              this.hasM1,
              this.timeSelected,
              this.date,
              this.$dayjs,
              this.seriesSelected == "energy_forecast" ||
              this.seriesSelected == "load_forecast"
            );
            // console.log("allchartData: ", this.allChartData);
            // this.overviewChartData(
            //   this.$store.getters.allChartData
            // );
            if (this.$store.getters.sn != -1) {
              this.data = this.allChartData[this.$store.getters.sn].data;
              // console.log("data_after: ", this.data);
              this.x_axis_type =
                this.allChartData[this.$store.getters.sn].x_axis_type;
            } else if ((this.seriesSelected != "m3" && this.seriesSelected != "m7") && (this.timeSelected == "day" || this.timeSelected == "quarter")) {
              const normalizedData = this.alignDataSets(this.$store.getters.allChartData);
              const newAllData = getProcessedChartData(
                normalizedData,
                this.x_axis_type,
                this.hasM1,
                this.timeSelected,
                this.date,
                this.$dayjs,
                this.seriesSelected == "energy_forecast" ||
                this.seriesSelected == "load_forecast"
              );
              this.data = this.allChartDataSummery(newAllData);
              // console.log("data_after: ", this.data);
              this.x_axis_type = this.data.x_axis_type;
              this.data = this.data.data;
            }
            else {
              // console.log("allChartData: ", this.allChartData);
              this.data = this.allChartDataSummery(this.allChartData);
              this.x_axis_type = this.data.x_axis_type;
              this.data = this.data.data;
            }

            //this.isLoading = false;
          } catch (error) {
            this.data = {};
          }
          this.$store.commit("setCurentChartData", this.data);
        })
        .catch((error) => {
          console.log("error getting chart's data", error);
        });
      },
  },
  mounted() {
    EventBus.$on('calldrawChart', this.drawChart);
    this.canvasContext = this.$refs.chartWrapper.getContext("2d");
    this.drawChart();
    const thisInstance = this
    this.$root.$on('updatechart', function () {
      thisInstance.drawChart()
    })
    this.checkTimeAndDrawChart();
    this.intervalId = setInterval(this.checkTimeAndDrawChart, 60000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState(['darkMode']),
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    curentSn() {
      return this.$store.getters.sn;
    },

    //Changes
    timeSeries() {
      return getTimeSeries(this.seriesSelected);
    },
    series() {
      return getSeries(this.$store.getters.sn);
    },
    seriesLabel() {
      return getSeriesLabel(this.$store.getters.sn);
    },
    seriesUnit() {
      return getSeriesUnit();
    },
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped lang="scss">
/*@media screen and (min-width: 320px) {

  .chart-wrapper {
    height: 350px;
  }

}
 @media screen and (min-width: 1024px) {

  .chart-wrapper {
    height: 350px;
  }

} 
@media screen and (min-width: 1430px) {

  .chart-wrapper {
    height: 440px;
  }

}
@media screen and (min-width: 2560px) {

  .chart-wrapper {
    height: 770px;
  }

}*/
.text-black {
  color: black !important;
}

.text-white {
  color: white !important;
}
</style>