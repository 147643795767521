<template>
  <section class="content">
    <div :class="{'text-white': !darkMode, 'text-dark': darkMode}">
      {{$t('oneItem.batterystatus')}}
    </div>
   
    <div :class="{'batteryShape':true, 'batteryShape-white': !darkMode, 'batteryShape-dark': darkMode }"  >
      <div class="level" >
        <div class="percentage" :style="{ width: batteryPercentage + '%' }"></div>
      </div>
    </div>
    <div :class="{'percent':true,'text-white':!darkMode,'text-dark':darkMode}" >{{ Math.floor(batteryPercentage) }}%</div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    batteryPercentage: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.updateBatteryStatus();
  },
  watch: {
    batteryPercentage() {
      this.updateBatteryStatus();
    }
  },
  methods: {
    updateBatteryStatus() {
      const percentage = this.$el.querySelector(".percentage");
      const percent = this.$el.querySelector(".percent");

      // Update DOM elements based on battery percentage
      percentage.style.width = this.batteryPercentage + "%";
      percent.innerHTML = Math.floor(this.batteryPercentage) + "%";

      // Change color based on battery level
      if (this.batteryPercentage <= 10) {
        percentage.style.background = "linear-gradient(90deg, #ff0000, #ff0000)";
      } else if (this.batteryPercentage <= 50) {
        percentage.style.background = "linear-gradient(90deg, #ffeb3b, #27ff00)";
      } else {
        percentage.style.background = "linear-gradient(90deg, #27ff00, #27ff00)";
      }
    }
  },
  computed: {
        ...mapState(['darkMode']),


    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    }
};
</script>

<style scoped>

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

.box h3 {
 
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 1px;
 
}

.batteryShape-white{
  border: 3px solid #285891;
}
.batteryShape-dark{
  border: 3px solid #fff;
}
.batteryShape {
  position: relative;
  width: 165px;
  height: 65px;
  margin: 20px 0;
  
  border-radius: 10px;
}

.batteryShape::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  width: 7px;
  height: 16px;
  background: #285891;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.batteryShape-white::before {
  background: #285891; 
}

.batteryShape-dark::before {
  background: #fff; 
}

.batteryShape::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.level {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  /* background: #333;#285891 */
  overflow: hidden;
}

.percentage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(90deg, #ffeb3b, #27ff00);
  border-radius: 4px;
}


.text-white {
  color: #285891!important;
  font-size: 1.5em;
}
.text-dark {
  color: #fff !important;
  font-size: 1.5em;
}
.percent {
 
  font-size: 2em;
  font-weight: 700;
}
</style>
