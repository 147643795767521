<template>

  <button v-if="isActive" @click="getSnData()" class="btn-success">
    <div v-if="sn == this.$store.getters.sns.length">
      {{ $t('SubNavBar.Overview') }}
    </div>
    <div class="butt" v-else v-html="label"></div> 
  </button>
  <button v-else @click="getSnData()" class="btn-info">
    <div class="overview" v-if="sn == this.$store.getters.sns.length">
      {{ $t('SubNavBar.Overview') }}
    </div>
    <div class="butt" v-else v-html="label"></div>
  </button>
</template>

<script>
import { store } from "@/store";

export default {
  name: "Serialnumber",
  data() {
    return {};
  },
  props: {
    sn: {
      required: true,
      type: undefined,
    },
    isActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    label: {
      type: String,
      required: true,
    }
  },
  methods: {
   
    resetSelectedOptions() {
    this.$emit('reset-options');
  },
    setActive() {
      let sns = this.$store.getters.sns;
      sns = sns.map((sn) => {
        if (sn.sn == this.$store.getters.sns[this.sn - 1].sn) {
          sn.isActive = true;
        } else sn.isActive = false;
        return sn;
      });
      this.$store.commit("updateSns", sns);
      this.$store.commit("setSn", this.$store.getters.sns[this.sn - 1].sn);
    },
    getSnData() {
      this.setActive();
      if (this.$store.getters.sn != -1) {
        this.$emit('reset-options');
        this.$store.dispatch("get_apex_chart_data", {
          sn: store.getters.sn,
          m2: true,
          m4: true,
          m5: true,
          days: 7,

        });
        let parames = {
          sn: store.getters.sn,

          days: 7,
        };
        this.$store.dispatch("get_soc_chart_data", parames);


      } else {
        // console.log("overview");
        this.$store.dispatch("get_apex_chart_data_overview", {
          m2: true,
          m4: true,
          m5: true,
          days: 7,
        });
        this.$store.dispatch("get_Soc_chart_data_overview", {

          days: 7,
        });
        this.$emit('reset-options');
       
      }

    },
  },
};
</script>

<style></style>